import { atom, selector } from 'recoil'
import { inspectionIdSelector } from '../Selectors/inspectionIdSelector'
import { readInspectionStatus } from '../../api/apis'
import { InspectionStatus } from '../../constants'

export const hasInspectionSignedState = atom<boolean>({
  key: 'hasInspectionSignedState',
  default:selector({
    key:'hasInspectionSignedState/default',
    get: async({ get }) => {
      const inspectionId = get(inspectionIdSelector)
      const { data } = await readInspectionStatus(+inspectionId)
      return data?.status === InspectionStatus.SIGNED
    },
  }),
})