import apiClient from './index'
import { OdoMeterStateType } from '../Recoil'
import {
  BASE64_PREFIX, BASE64_PREFIX_VIDEO, keyOptionList, LANGUAGE_COOKIE, LocalStorageKeys, ROUTES,
} from '../constants'
import i18n from '../utils/i18n'
import * as Sentry from '@sentry/react'
import { externalAspects } from '../constants/videoCameraConstants'
import axios from 'axios'
import { memoize } from 'lodash-es'
import Cookies from 'js-cookie'

const transformedLanguageMap = memoize(
  (languageMap) => languageMap.reduce((acc, lang) => {
    acc[lang.code.toLowerCase()] = lang.id
    return acc
  }, {}),
)
const DEFAULT_LANGUAGE_CODE = '0'

const getLanguage = (languageMap: Record<string, string>) => {
  const selectedLanguage = localStorage.getItem(LANGUAGE_COOKIE)

  if (selectedLanguage && languageMap) {
    const langMap = transformedLanguageMap(languageMap)
    if (Object.keys(langMap).includes(selectedLanguage)) {
      return langMap[selectedLanguage]
    }
  }
  return DEFAULT_LANGUAGE_CODE
}

const ADDRES_SERVICE_URL = 'https://zipcodeservice.terbergleasing.nl/'
const REFRESH_AUTH = 'authentication/refresh'
const GET_TOKEN = 'getToken'
const GET_INSPECTION_ID = 'inspection/getIdByUuid'
const GET_INSPECTION_LIST = 'inspection/list'
const GET_LEASE_OBJECTS = 'leaseObject/list'
const GET_LEASE_DETAIL = 'leaseObject/read'
const GET_INSPECTION_BASIC = 'inspection/readBasic'
const GET_OPTIONS_LIST = 'optionList/list'
const GET_DAMAGES = 'inspection/readDamages'
export const GET_EXTERIOR = 'inspection/readExterior'
const GET_ODO_METER = 'inspection/readMileage'
const GET_ACCESSORIES = 'inspection/readShortcoming'
const GET_LOCATION_LIST = 'general/locationList'
const GET_LOCATION_INFO = 'inspection/readLocation'
const GET_TIRES = 'inspection/readTires'
export const GET_INTERIOR = 'inspection/readInterior'
const GET_LANGUAGES = 'general/languageList'
const UPDATE_INSPECTION_BASIC = 'inspection/updateBasic'
const SET_ODO_METER = 'inspection/updateMileage'
const UPDATE_DAMAGES = 'inspection/updateDamages'
const DELETE_DAMAGES = 'inspection/deleteDamages'
export const UPDATE_EXTERIOR = 'inspection/updateExterior'
const UPDATE_ACCESSORIES = 'inspection/updateShortcoming'
const UPDATE_LOCATION = 'inspection/updateLocation'
const ADD_LOCATION = 'general/locationCreate'
const CHECK_SUBMISSION_STATUS = 'inspection/checkCompleteShort'
const UPDATE_SIGNING = 'inspection/updateSigning'
const UPDATE_TIRES = 'inspection/updateTires'
export const UPDATE_INTERIOR = 'inspection/updateInterior'
const UPDATE_STATUS = 'inspection/statusChange'
const UPDATE_MEMO = 'general/memoCreate'
const UPDATE_VIDEO = 'inspection/binaryMedia'
export const INSPECTION_STATUS = 'inspection/readStatus'
const GET_OVERVIEW = 'inspection/readOverview'
const UPDATE_OVERVIEW = 'inspection/updateOverview'
const GET_TENANT_DATA = 'tenant/read'

interface GeneralResponse {
  headers: any,
  request: any,
  status: number,
  statusText: string,
}

export interface UpdateStatusType {
  inspectionId: number
  condition?: string
}

interface UpdateNoteType {
  inspectionId: number
  note?: string
}

export const refreshAuth = async() => {
  apiClient.post(REFRESH_AUTH)
}

const postRequest = async({ endpoint, ...rest }) =>
  apiClient.post(endpoint, rest)


export const getLanguages = async(inspectionId: number) => {
  const reponse = await postRequest({
    endpoint: GET_LANGUAGES,
    filter: [
      {
        key: 'inspection',
        value: inspectionId,
      },
    ],
  })
  return reponse?.data?.languages
}

export const getInspectionIdByUuid = async(uuid) => {
  const response = await postRequest({
    endpoint: GET_INSPECTION_ID,
    uuid,
  })
  return response?.data?.id
}

export const loginSuperUser = async() => {
  try {
    return await postRequest({ endpoint: GET_TOKEN })
  } catch (error) {
    Sentry.captureException('Getting Token encounters error', error)
  }
}

export const getLeaseObjects = async() => {
  const response = await postRequest({ endpoint: GET_LEASE_OBJECTS })
  return response?.data?.leaseObject ?? []
}

export const updateVideo = async(formData: FormData, token: string) => {
  const TAKE_OUT_WEB_SERVICES_REGEX = /webservices$/
  const ENDPOINT = Cookies.get('isBE') === 'true' ? process.env.REACT_APP_BASE_URL_BE : process.env.REACT_APP_BASE_URL
  const SPECIAL_BASE_URL = ENDPOINT?.replace(TAKE_OUT_WEB_SERVICES_REGEX, '')
  const data = await axios.post(
    `${SPECIAL_BASE_URL}${UPDATE_VIDEO}`,
    formData,
    {
      headers: { 'Authorization': `Bearer ${token}` },
    },
  )
  return data
}

export const getInspectionData = async(inspectionId, languageMap) => {
  try {
    const response = await postRequest({
      endpoint: GET_INSPECTION_LIST,
      filter: [
        {
          key: 'id',
          value: inspectionId,
        },
      ],
      language: getLanguage(languageMap),
    })

    const inspection = response?.data?.inspections?.[0]
    localStorage.setItem(LocalStorageKeys.REASON, inspection?.typeLabel)
    localStorage.setItem(LocalStorageKeys.InspectionData, JSON.stringify({
      inspectorRole: inspection?.inspectorType,
      inspectionReason: inspection?.type,
      inspectionStatus: inspection?.status,
    }))
    // XXX Do not add any more localstorage key here. Read the FIXME above and refactor
    return inspection ?? {}
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const getLeaseDetail = async(leaseId, languageMap) => {
  try {
    const response = await postRequest({
      endpoint: GET_LEASE_DETAIL,
      id: leaseId,
      language: getLanguage(languageMap),
    })
    return response?.data?.property ?? {}
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const getLeaseId = async(inspectionId) => {
  try {
    const response = await postRequest({
      endpoint: GET_INSPECTION_BASIC,
      id: inspectionId,
    })
    const currentTheme = localStorage.getItem(LocalStorageKeys.THEME)
    if (currentTheme !== response?.data?.theme) {
      localStorage.setItem('theme', response?.data?.theme)
    }
    return response?.data?.leaseObject
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const updateBasic = async(inspectionId, acceptationData) => {
  try {
    const response = await postRequest({
      endpoint: UPDATE_INSPECTION_BASIC,
      id: inspectionId,
      data: acceptationData,
    })
    return response?.data
  } catch (e) {
    Sentry.captureException(e)
  }
}

type InspectionBasicResponse = {
  inspectorType: number
  mediaAnalysisType: number
  created: string
  type: number
  displayId: string
  dossierId: string
  inspector: number
  inspectorOriginal: number
  leaseObject: number
  remarketing: number
  report: string
  shortReport: string
  status: number
  inspectionDate: string
  inspectionDateOriginal: string
  intakeDate: string
  internalInspectionNote: string
  shortInspection: boolean
  theme: string;
  uuid: string
  qrCode?: {
    filename: string
    data: string
  }
}

export const getInspectionBasic = async(inspectionId) => {
  try {
    return await postRequest({
      endpoint: GET_INSPECTION_BASIC,
      id: inspectionId,
    }).then(response => response?.data as InspectionBasicResponse)
  } catch (e) {
    Sentry.captureException(e)
  }
}

type TypeKeyOptionList = typeof keyOptionList.SECTION | typeof keyOptionList.CATEGORY

interface IGetOptionList {
  keyValue: TypeKeyOptionList,
  value: number,
  languageMap: any,
  inspectionId: number
}

export const getOptionsList = async({ keyValue, value, languageMap, inspectionId }: IGetOptionList) => {
  try {
    return await postRequest({
      endpoint: GET_OPTIONS_LIST,
      inspection: inspectionId,
      filter: [
        { key: keyOptionList.LANGUAGE, value: getLanguage(languageMap) },
        { key: keyValue, value: value }],
    }).then(response => response?.data?.options ?? [])
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const getOdoMeterData = async(inspectionId: string) => {
  try {
    const response = await postRequest({
      endpoint: GET_ODO_METER,
      id: inspectionId,
    })
    return {
      id: +inspectionId,
      image: response?.data.mediaBase64 ? BASE64_PREFIX + response.data.mediaBase64.data : '',
      mileage: response?.data.currentMileage,
    }
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const setOdoMeterData = async(odoData: OdoMeterStateType) => {
  const newImg = odoData.image.substring(odoData.image.indexOf(',') + 1)
  try {
    return await postRequest({
      endpoint:SET_ODO_METER,
      id: odoData.id,
      data:
        {
          currentMileage: odoData.mileage,
          mediaBase64: {
            filename: `odoMeterPhoto-${odoData.id}.jpg`,
            data: newImg,
          },
        },
    })
  } catch (e) {
    Sentry.captureException(e)
    return e?.response
  }
}

export const getDamages = async(inspectionId: string, languageMap, damageId?: string) => {
  try {
    const response = await postRequest({
      endpoint: GET_DAMAGES,
      id: +inspectionId,
      ...(damageId && { damage: +damageId }),
      language: getLanguage(languageMap),
    })

    return response?.data?.damages?.map(item => {
      const media = item.media?.map((el, i) => ({
        id: String(el.item),
        image: BASE64_PREFIX + el.itemBase64?.data,
        labelText: i === 0 ? i18n.t('overview-picture') : i18n.t('detail-picture'),
      }),
      )
      return {
        id: item.id,
        damagePart: { code: item.part, label: item.partLabel },
        damageDetail: { code: item.damageType, label: item.damageTypeLabel },
        repairType: { code: item.repairType, label: item.repairTypeLabel },
        acceptable: item.acceptable,
        repairCosts: item.repairCostsInitial,
        images: media,
        shortage: item.shortage,
        status: item.status,
      }
    }) ?? []
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const getOverview = async(inspectionId:string) => {
  const requiredAspects = localStorage.getItem(LocalStorageKeys.RequiredAspects)
  try {
    const response = await postRequest({
      endpoint: GET_OVERVIEW,
      id: Number(inspectionId),
    })
    if (response?.data) {
      const imageMasks = response?.data?.masks
      const imageIcons = response?.data?.icons
      const images = response?.data?.media?.reduce((col, { option, itemBase64 }) => {
        if (option && requiredAspects.includes(option)) {
          col[option] = option === externalAspects.videoAspect
            ? { ...itemBase64, data: itemBase64 ? `${BASE64_PREFIX_VIDEO}${itemBase64.data}` : null }
            : { ...itemBase64, data: itemBase64 ? `${BASE64_PREFIX}${itemBase64.data}` : null,
              mask: imageMasks.find(el => el.parent === option)?.item || null,
              icon: imageIcons.find(el => el.parent === option)?.item || null
            }
        }
        return col
      }, {})
      return images
    }
    return {}
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const deleteDamages = async({ leaseId, hasAllDelete, damageId }) => {
  try {
    await postRequest({
      endpoint: DELETE_DAMAGES,
      id: Number(leaseId),
      damage: damageId,
      all: hasAllDelete,
    })
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const updateOverview = async({ inspectionId, data }) => {
  try {
    return await postRequest({
      endpoint: UPDATE_OVERVIEW,
      id: +inspectionId,
      data,
    })
  } catch (e) {
    Sentry.captureException(e)
    return e?.response
  }
}

interface DamageData {
  part?: string;
  damageType?: string;
  acceptable?: boolean;
  initialRepairCosts?: number;
  remarkInspector?: string;
  status?: number;
  media: {
    type: number;
    itemBase64: {
      filename: string;
      data: string;
    }
  }[]
}
export const updateDamages = async(leaseId: string, newDamage: DamageData, damageIdString?: string) => {
  const damageId = damageIdString ? Number(damageIdString) : null
  try {
    const res = await postRequest({
      endpoint: UPDATE_DAMAGES,
      id: Number(leaseId),
      data: { damages: [newDamage] },
      damage: damageId,
    })
    return res
  } catch (e) {
    Sentry.captureException(e)
    return e?.response
  }
}

export const getAccessories = async(inspectionId) => {
  try {
    const res = await apiClient.post(GET_ACCESSORIES, {
      id: Number(inspectionId),
    })
    return res.data.checks
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const updateAccessories = async(inspectionId, accessoryData) => {
  try {
    const res = await apiClient.post(UPDATE_ACCESSORIES, {
      id: +inspectionId,
      data: accessoryData,
    })
    return res
  } catch (e) {
    Sentry.captureException(e)
  }
}
export const getLocations  = async(inspectionId: number): Promise<any> => {
  try {
    return await postRequest({
      endpoint: GET_LOCATION_LIST,
      filter:
        [
          { key: 'inspection', value: inspectionId }
        ],
    })
  } catch (e) {
    Sentry.captureException(e)
  }
}

interface UpdateLocation {
  inspectionId: number,
  data: {
    location: number,
    parkingSpace?: string
  }
}

interface LocationResponse extends GeneralResponse {
  data: {
    error: [],
    location: number,
    parkingSpace?: string,
  }
}

interface Address {
  zipCode: string,
  houseNumber: string,
}

export const getLocationInformationByInspectionId = async(inspectionId) => {
  try {
    return await postRequest({
      endpoint: GET_LOCATION_INFO,
      id: inspectionId,
    }).then((res) => res as unknown as LocationResponse)
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const updateLocation = async(locationData:UpdateLocation) => {
  try {
    return await postRequest({
      endpoint: UPDATE_LOCATION,
      id: locationData.inspectionId,
      data: locationData.data,
    })
  } catch (e) {
    Sentry.captureException(e)
  }
}

type GetAddress = (address:Address) => Promise<{ status: string, street: string, city: string, province:string }>

export const getAddress: GetAddress = async(address: Address) => {
  const addressResponse = await fetch(`${ADDRES_SERVICE_URL}${address.zipCode}/${address.houseNumber}`)
  const json = await addressResponse.json()
  return json
}

type LocationRequest = {
  postalCode: string,
  houseNumber: string,
  street: string,
  city: string
}

export const addLocation = async(data: LocationRequest, inspectionId) => {
  try {
    return await postRequest({
      endpoint: ADD_LOCATION,
      property: data,
      id: inspectionId,
    })
  } catch (e) {
    Sentry.captureException(e)
  }
}

type CustomerInfoType = {
  inspectionId: number,
  emailAddress: string,
  firstName: string,
  lastName: string
}

export const submitCustomerInfo = async(
  { inspectionId, emailAddress, firstName, lastName }: CustomerInfoType,
) => {
  const newDate = new Date()
  try {
    const response = await postRequest({
      endpoint: UPDATE_SIGNING,
      id: inspectionId,
      data: {
        emailAddress,
        firstName,
        lastName,
        signDate: `${newDate.toISOString().split('T')[0]} ${newDate.getHours()}:${newDate.getMinutes()}`,
      },
    })
    return response?.data
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const getSubmissionStatus = async(inspectionId: number) => {
  try {
    return await postRequest({
      endpoint: CHECK_SUBMISSION_STATUS,
      id: inspectionId,
    })
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const getTires = async(inspectionId: number) => {
  try {
    const data = await postRequest({
      endpoint: GET_TIRES,
      id: inspectionId,
    })
    return data?.data
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const updateTires = async(inspectionId: number, data) => {
  try {
    return await postRequest({
      endpoint: UPDATE_TIRES,
      id: inspectionId,
      data: data,
    })
  } catch (e) {
    Sentry.captureException(e)
    return e?.response
  }
}

interface IGetCheckerStepData {
  keyValue: TypeKeyOptionList,
  value: number,
  inspectionId: string,
  group: number,
  languageMap: any,
  getDataEndpoint: string
}
export const getCheckerStepData = async({
  keyValue, value, inspectionId, group, languageMap, getDataEndpoint,
}: IGetCheckerStepData) => {
  try {
    const data = await postRequest({
      endpoint: getDataEndpoint,
      id: Number(inspectionId),
      filter: [
        { key: keyOptionList.GROUP, value: group },
      ],
    })

    const childrenList = data?.data?.checksChildren

    const optionData = await postRequest({
      endpoint: GET_OPTIONS_LIST,
      ... (Number(inspectionId) && { inspection: Number(inspectionId) }),
      filter: [
        { key: keyOptionList.LANGUAGE, value: getLanguage(languageMap) },
        { key: keyValue, value: value },
        { key: keyOptionList.GROUP, value: group },
      ],
    })
    const allData = data?.data
    const result = allData.checks?.map(item => {
      const neededItem = optionData?.data?.options?.find(el => el.code === item.item)
      return { ...item, title: neededItem?.label, childType: neededItem?.childType,
        check: childrenList?.map(el => el?.item).includes(item?.item) ? true : item?.check }
    })
    const mediaData = allData?.media
    const allChildren = allData?.checksChildren.filter(child => child.item.includes(child.check))
    const updatedMedia = mediaData.map(el => el?.itemBase64
      ? { item: el?.option, itemBase64: { data: `${BASE64_PREFIX}${el?.itemBase64.data}`,
        filename: el?.itemBase64?.filename } }
      : { item: el?.option, itemBase64: null })
    return { checks: result?.filter(item => !item.parent).map(el => {
      const children = result?.filter(child => child.parent === el.item).map((item, i) => {
        return { ...item, id: i }
      })
      return  { ...el, children: children }
    }), media: updatedMedia ?? [], children: allChildren }
  } catch (e) {
    Sentry.captureException(e)
  }
}

interface IUpdateCheckerStepData {
  inspectionId: string,
  finalData: {
    checks: { item: string, media: string, check: boolean, mediaBase64: { filename: string, data: string } }[]
    checksChildren: { check: string, item: string }[],
  },
  updateDataEndpoint: string
}

export const updateCheckerStepData = async({ inspectionId, finalData, updateDataEndpoint }: IUpdateCheckerStepData) => {
  try {
    return await postRequest({
      endpoint: updateDataEndpoint,
      id: Number(inspectionId),
      data: finalData,
    })
  } catch (e) {
    Sentry.captureException(e)
    return e?.response
  }
}

// this is different from getInspectionStatus. that function should be refactored
// when BE supports flow type
export const readInspectionStatus = async(inspectionId: number) => {
  try {
    return await postRequest({
      endpoint: INSPECTION_STATUS,
      id: inspectionId,
    })
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const updateStatus = async({ inspectionId, condition }: UpdateStatusType) => {
  try {
    return await postRequest({
      endpoint: UPDATE_STATUS,
      id: inspectionId,
      ...(condition && { condition }),
    })
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const updateNote = async({ inspectionId, note }: UpdateNoteType) =>{
  try {
    const response = await postRequest({
      endpoint: UPDATE_MEMO,
      inspection: inspectionId,
      content: note,
    })

    return response?.data
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const getFlowSteps = async(inspectionId: number) => {
  try {
    const response = await postRequest({
      endpoint: GET_TENANT_DATA,
      inspection: inspectionId,
      filter: [{
        key: 'module',
        value: 'Inspection'
      }]
    })
    const stepsConfig = []
    response?.data?.modules[0]?.subModules?.forEach((step) => {
      if (step?.isModuleEnabled) {
        if (!step?.subPath) {
          stepsConfig.push(step)
        } else if (step?.subPath?.length !== 0) {
          const allSubPaths = step?.subPath?.split(', ')
          allSubPaths.map((el: string) => stepsConfig.push({ ...step, path: `${step.path}/${el}`, subPath: null }))
        }
      }
    })
    return stepsConfig.map((el, i) => {
      return {
        ...el, progressbar: (el.path !== `/${ROUTES.PRIVATE_PATH.WELCOME}`
          && el.path !== `/${ROUTES.PUBLIC_PATH.THANK_YOU}`)
          ? i * 100 / (stepsConfig.length - 2) : 0
      }
    })
  } catch (e) {
    Sentry.captureException(e)
  }
}
